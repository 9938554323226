import React, { useEffect, useState } from "react";
import "../styles/sticky.css";
import Stacks from "../Components/StackComponent";
import image1 from "../Attachments/right-1.png";
import image2 from "../Attachments/right-2.png";
import image3 from "../Attachments/right-3.png";

const HelpSection: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState<string>("");

  const imageUrls = [image3, image1, image2];

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const sectionHeight = window.innerHeight;
    const totalHeight = document.documentElement.scrollHeight;

    const scrollPercent =
      (scrollPosition / (totalHeight - sectionHeight)) * 100;

    if (scrollPercent < 33) {
      setCurrentImage(imageUrls[0]);
    } else if (scrollPercent < 43) {
      setCurrentImage(imageUrls[1]);
    } else {
      setCurrentImage(imageUrls[2]);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(document.querySelector(".help-section")!);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`wrapping ${isVisible ? "fade-in" : "fade-out"}`}>
      <div className={`help-section ${isVisible ? "slide-up" : ""}`}>
        <div className="left-section">
          <h1 className="main-title">How Jyve can help you</h1>

          <img
            className="images"
            src={currentImage || imageUrls[0]}
            alt="Help Section"
          />
        </div>

        <div className="right-section">
          <Stacks />
        </div>
      </div>
    </div>
  );
};

export default HelpSection;
