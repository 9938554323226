import React from 'react';

const Privacy: React.FC = () => {
  return (
    <div className='p-12 mt-12'>
       <p className='text-1xl'>1. Privacy Policy

Jyve’s Privacy Policy sets out how Jyve, together with its parents, subsidiaries, and affiliates (collectively, “Jyve”, “We” or “Us”), process the personal information of independent contractors who use the Jyve mobile application and platform to perform gig work for Jyve’s clients, as well as California-based users of our website, (hereinafter, collectively, “You”). We are committed to protecting the privacy and security of Your personal information. This Privacy Policy describes how We collect and use personal information about You during and after Your working/contracting relationship with us. We have a separate policy which applies to the data of Jyve’s employees. We are responsible for deciding how We hold and use personal information about You. We are required under the California Consumer Privacy Act (“CCPA”), as amended, effective January 1, 2020, to provide You with notice at the time of collection if you are a California resident.

This policy applies to applicants, current and former employees, and current and former contractors and does not form part of any contract of employment or other contract to provide services. We may update this policy at any time.

It is important that You understand this policy, together with any other privacy notices We may provide on specific occasions when We are collecting or processing personal information about You, so that You are aware of how and why We are using such information. If You have any questions about this privacy policy or how We handle Your personal information, please contact us by email to help@jyve.com or by mail to Jyve 340 S Lemon Ave #5983, Walnut, CA 91789.

This privacy policy applies to transactions and activities and data gathered through the Jyve hosted platform, the Jyve website (www.jyve.com), and Our mobile applications (collectively the “Jyve Platform”). Please review this privacy policy periodically as We may revise it without notice. This privacy policy was last revised effective January 1, 2020. Each time You use the Jyve Platform or provide Us with information, by doing so You are accepting the practices described in this privacy policy at that time.

2. Data We Collect From You

In order to operate the Jyve Platform and to provide You with information about products or services that may be of interest to You, We may collect “personal information” (e.g., information that could be used to contact You directly (without using the Jyve Platform) such as full name, postal address, phone number, credit/debit card information, or email address) or “demographic information” (e.g., information that You submit, or that We collect, that is not personal information; this may include, but is not limited to, zip code, hometown, gender, username, age/birth date, browsing history information, searching history information, and registration history information). We will also collect Your precise location when You interact with the Jyve Platform; the contact information of Your friends, if You choose to connect Your contacts and address book information with the Jyve Platform; and Your login credentials to Your social network accounts, such as Facebook and Twitter, if You choose to connect those accounts with Your Jyve account. You represent and warrant that You have the authority to provide Us with any such contact information. Demographic information is divided into two categories:

“non-public information”, which consists of transaction information and one-on-one communications between You and other users of the Jyve Platform; and
“public information”, which consists of all other demographic information.
Please note that nowhere on the Jyve Platform do We knowingly collect, keep or maintain personal information from children under the age of 18, as We require that all users represent to Us that they are at least 18 years old.



For CA job applicants, please refer to JYVE’s PRIVACY NOTICE TO CALIFORNIA APPLICANTS FOR EMPLOYMENT REGARDING THE COLLECTION OF PERSONAL INFORMATION for what information We collect about job applicants and how We use and disclose it.

For CA contractor applicants, please refer to JYVE’s PRIVACY NOTICE TO CALIFORNIA JYVE CONTRACTOR APPLICANTS REGARDING THE COLLECTION OF PERSONAL INFORMATION for what information We collect about contractor applicants and how We use and disclose it.

For CA employees and former employees, please refer to JYVE’s PRIVACY NOTICE TO CALIFORNIA EMPLOYEES REGARDING THE COLLECTION OF PERSONAL INFORMATION for what information We collect about employees and former employees and how We use and disclose it.

3. Cookies

Whenever You interact with the Jyve Platform, We may automatically receive and record information on Our server logs from Your browser (such as Google Chrome) or device (such as Your smartphone), which may include Your IP address, device identification, “cookie” information, the type of browser and/or device You are using to access the Jyve Platform, and the page or feature You requested. “Cookies” are identifiers We transfer to Your browser or device that allow Us to recognize Your browser or device and tell Us how and when pages and features in the Jyve Platform are visited and by how many people. You may be able to change the preferences on Your browser or device to prevent or limit Your device’s acceptance of cookies, but this may prevent You from taking advantage of some of our features.

Our advertising partners may also transmit cookies to Your browser or device, when You click on ads that appear in the Jyve Platform. Also, if You click on a link to a third-party website or service, such third party may also transmit cookies to You. Again, this privacy policy does not cover the use of cookies by any third parties, and We are not responsible for their privacy policies and practices.

We may use this data to customize and optimize content for You, based on Your usage patterns. We may also use it to improve the Jyve Platform (for example, this data can tell Us how often users use particular features of the Jyve Platform – and We can use that knowledge to help optimize the features We offer).

4. How We Use Personal Information

We use Your email address and Your other personal information to help Us efficiently operate the Jyve Platform, for example: to contact You in connection with Your transactions and other activities on the Jyve Platform (including, but not limited to, confirmation emails, or important news that could affect Your relationship with Jyve), to forward information to You from other Users, and to forward information from You to other Users. We use Your contact information to find and connect with Your friends (when instructed by You). These types of communications are known as “Operational Communications.” In some cases, Operational Communications may also contain commercial messages, such as banner ads and special offers.

To operate the Jyve Platform, including processing Your transactions and supporting Your activities on the Jyve Platform, We may share Your personal information with Our agents, representatives, contractors and other service providers so they can provide Us with support services such as email origination, receipt or support services, customer relationship management services, and order fulfillment. We require these entities not to use Your information for any other purpose.

By purchasing, or registering or making reservations for, products or services offered or sponsored by third parties on the Jyve Platform, or electing to receive communications (such as emails or material by mail) or electing to participate in contests, sweepstakes or other programs (such as discount or rewards programs), offered or sponsored by third parties on the Jyve Platform, You consent to Our providing Your personal information to those third parties. Those third parties are not bound by this Privacy Policy and may use Your personal information in accordance with their own privacy policies. You will need to contact those third parties to instruct them directly regarding Your preferences for the use of Your personal information by them. Additionally, You agree that We may use and disclose all such information so submitted to such third parties in the same manner in which We are entitled to use and disclose any other information You submit to Us.


We will only use Your personal information for the purposes We have disclosed in our notice(s). If We need to use Your personal information for an unrelated purpose, We will notify You.

5. How We Use Demographic Information

We may review all demographic information (e.g., information that You submit, or that We collect, that is not personal information; this may include, but is not limited to, zip code, hometown, gender, username, age/birth date, browsing history information, searching history information, and registration history information). We may use public information to enable other users to search Your profile, to determine whether Your details fit other user’s requirements, and to communicate with You. We may use demographic information to tailor the Jyve Platform and communications to Your interests.

6. How We Use Geolocation Data

We collect the precise location (i.e., Your geographic position) of Jyve contractors who select and perform Tasks through the Jyve Platform in order to verify that the contractor is physically present at the location when the contractor indicates that they have begun and finished a claimed task, which allows Us to verify that the contractor performed the task they have claimed and negotiated a fee for.  Therefore, to the extent You are a Jyve contractor who selects and performs tasks, You need to ensure that Your device permits Us to collect geolocation data through the Jyve Platform. We do not share location data with any data broker or advertiser, only with the client whom You are providing work for.

7. Information Retention


We only retain Your personal information for as long as necessary to fulfill the purposes We collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal information, We consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of Your personal information, the purposes for which We process Your personal information and whether We can achieve those purposes through other means, and the applicable legal requirements.

Once You are no longer an employee or contractor of Jyve, We will securely retain or destroy Your personal information in accordance with Jyve’s record retention policies.

In some circumstances We may anonymize Your personal information so that it can no longer be associated with You, in which case We may use such information without further notice to You.


8. Failure to Provide Personal Information, Choice, Opt-Out

Jyve provides You the opportunity to opt-out of receiving communications from Us and Our partners at the point where We request information about You. Jyve gives Users the option to remove their information from Our database, to not receive future communications or to no longer receive Our service. 

If You fail to provide certain information when requested, it could hinder our ability to perform certain obligations (such as paying You or providing a benefit), or prevent compliance with certain legal obligations (such as health and safety issues).

9. Special Cases in Which We Share Personal Information

Your personal information may be passed on to a third party in the event of a transfer of ownership or assets, or a bankruptcy. We may also disclose personal information when We determine that such disclosure is necessary to comply with applicable law, to cooperate with law enforcement or to protect the interests or safety of Jyve or other users of the Jyve Platform or mobile app, or visitors to the Jyve website. We also may disclose Your personal information to Our subsidiary and parent companies and businesses, and other affiliated legal entities and businesses with whom We are under common corporate control. Whenever personal information is disclosed under this paragraph, We may also disclose Your demographic information along with it, on a non-anonymous basis. All of Our parent, subsidiary and affiliated legal entities and businesses that receive Your personal information or non-anonymous demographic information from Us will comply with the terms of this privacy policy with respect to their use and disclosure of such information.

10. Data Protection and Our Security Precautions

We will comply with applicable data protection law, which says that the personal information We hold about You must be: (i) used lawfully, fairly and in a transparent way; (ii) collected only for valid purposes that We have clearly explained to You and not used in any way that is incompatible with those purposes; (iii) relevant to the purposes We have told You about and limited only to those purposes; (iv) accurate and kept up to date; (v) kept only as long as necessary for the purposes We have told You about; and (vi) kept securely.

Your Jyve Profile is password-protected so that only authorized Jyve staff have access to Your account information. If You have registered for Jyve using Facebook Connect, then Your login and password shall be the same as Your Facebook login and password. In order to maintain this protection, do not give Your password to anyone. Jyve staff will never proactively reach out to You and ask for any personal account information, including Your password. If You share a computer, You should sign out of Your Jyve account and close the browser window before someone else logs on. This will help protect Your information entered on public terminals from disclosure to third parties.

Jyve makes every effort to ensure that Your information is secure on its system. Jyve is dedicated to maintaining Our privacy policy as set forth herein and other privacy initiatives, periodically reviewing Web security and making sure that every Jyve employee is aware of Our security practices. Unfortunately, no data transmission over the Internet can be guaranteed to be 100% secure. As a result, Jyve cannot guarantee the security of any information You transmit to Us, and You do so at Your own risk. If You have any further questions on this issue, refer to Jyve’s Terms of Use. Jyve expressly disclaims any liability that may arise should any other individuals obtain the information You submit to the Jyve Platform.

Jyve has security measures in place to protect against the loss, misuse and alteration of the information under Our control. Your information may be transferred to and maintained on computer networks which may be located outside of the state, province, country or other governmental jurisdiction in which You reside, and the country or jurisdiction in which these computer networks are located may not have privacy laws as protective as the laws in Your country or jurisdiction.

The Jyve Platform may contain links to other web sites. We are of course not responsible for the privacy practices of other web sites. We encourage Our Users to be aware when they leave the Jyve Platform to read the privacy statements of each and every web site that collects personally identifiable information. This Privacy Policy applies solely to information collected by the Jyve Platform.

Although no data security system can fully protect personal information from unauthorized data breaches, Jyve has implemented reasonable safeguards and controls, consistent with its legal obligations under California and other local, state and federal laws. Jyve is committed to: (i) seeking to safeguard all personal information that You provide to us; (ii) seeking to ensure that it remains confidential and secure; and (iii) taking reasonable steps to ensure that personal privacy is respected. All our data is stored in written or electronic form on our servers and computers and in various physical locations. We maintain physical, electronic and procedural safeguards to protect Your personal information from misuse, unauthorized access or disclosure and loss or corruption by computer viruses and other sources of harm. We restrict access to personal information to those staff members, Jyve, and third parties who need to know that information for the purposes identified in our notice(s).

We have put in place procedures to deal with any suspected data security breach and will notify You and any applicable regulator of a suspected breach when We are legally required to do so.

11. Changes To This Privacy Policy
As We strive to improve Our practices, We may review Jyve’s Privacy Policy and other privacy practices from time to time. We reserve the right to change this policy at any time and to notify You of those changes by posting an updated version of this policy. It is Your responsibility to check this policy from time to time for any changes.
If at any point We decide to use particular personally identifiable information in a manner materially different from that stated at the time it was collected, We will notify Users by way of an email or by providing 30 days’ notice on the Jyve Platform. We also encourage You to review this privacy policy periodically. By using the Jyve Platform, You do hereby represent and warrant that You have read, understand and agree to all terms of Agreement. Each time You use the Jyve Platform, You agree to all terms set forth in this Agreement and any other policies published by Jyve on the Jyve Platform. Please note that We will continue to have the right to change Our privacy policy and practices, and how We use Your personally identifiable information, without notice, as described in herein, provided that such changes shall only apply to information gathered on or after the date of the change.

12. Contacting Jyve

If You have any questions about this privacy statement, the practices of Jyve, or Your dealings with Jyve, You may contact us at 1-855-JYVE777.</p>
    </div>
  );
};

export default Privacy;
