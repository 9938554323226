
import image1 from '../Attachments/Carousel-images/Fritolay_company_logo.svg.png';
import image2 from '../Attachments/Carousel-images/PensaWhite2-01.png';
import image3 from '../Attachments/Carousel-images/crossmark-removebg-preview.png';
import image5 from '../Attachments/Carousel-images/indie-camp-removebg-preview.png';
import image6 from '../Attachments/Carousel-images/mars-logo-475x200-removebg-preview.png';
import image7 from '../Attachments/Carousel-images/mizo-logo-final-02-removebg-preview.png';
import image8 from '../Attachments/Custom SVG/pepsico-logo-svg-vector.svg'
import image9 from '../Attachments/Carousel-images/ef-logo-green-pms7739.png'
import image10 from '../Attachments/Carousel-images/logo_KeHE-fullC_blue_text_2023.png'
import image11 from '../Attachments/Carousel-images/Meijer_logo.svg.png'
import image12 from '../Attachments/Carousel-images/PopCornerslogo.png'
import image13 from '../Attachments/Carousel-images/red-bull-logo-png-transparent.png'
import image14 from '../Attachments/Carousel-images/Acosta-in-circle.png'
const carouselImages = [
  image1,
  image2,
  image3,
  image6,
  image5,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14

];

export default carouselImages;
