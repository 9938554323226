import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCogs, faChartLine, faHandshake, faBriefcase, faTrophy } from '@fortawesome/free-solid-svg-icons';
import '../styles/getstarted.css'; 

interface Feature {
  icon: React.ReactNode; 
  title: string;
  subheading: string;
}

const GetStartedSection: FC = () => {
  const [selected, setSelected] = useState<'jyver' | 'partner'>('jyver');

  const handleSelection = (type: 'jyver' | 'partner') => {
    setSelected(type);
  };

  const content: Record<string, Feature[]> = {
    jyver: [
      {
        icon: <FontAwesomeIcon icon={faUser} className="icon" />, 
        title: 'Sign Up',
        subheading: 'Sign-up to join Jyve on your mobile device',
      },
      {
        icon: <FontAwesomeIcon icon={faCogs} className="icon" />, 
        title: 'Demo your skills',
        subheading: 'Learn brand standards.',
      },
      {
        icon: <FontAwesomeIcon icon={faChartLine} className="icon" />, 
        title: 'Start Jyving',
        subheading: 'Start Jyving, start earning.',
      },
    ],
    partner: [
      {
        icon: <FontAwesomeIcon icon={faHandshake} className="icon" />, 
        title: 'Sign Up',
        subheading: 'Sign-up on Jyve ',
      },
      {
        icon: <FontAwesomeIcon icon={faBriefcase} className="icon" />, 
        title: 'Post Job',
        subheading: 'Post a job with description of what you need ',
      },
      {
        icon: <FontAwesomeIcon icon={faTrophy} className="icon" />, 
        title: 'Hire a Jyver',
        subheading: 'Hire a Jyver and get your job done ',
      },
    ],
  };

  return (
    <section className="get-started-section">
      <h2 className="get-started-title">Get started with three easy steps</h2>
      <div className="button-container">
        <button
          className={`button jyver ${selected === 'jyver' ? 'selected' : ''}`}
          onClick={() => handleSelection('jyver')}
        >
          Jyver
        </button>
        <button
          className={`button partner ${selected === 'partner' ? 'selected' : ''}`}
          onClick={() => handleSelection('partner')}
        >
          Brands/Retailers
        </button>
      </div>
      <div className="gradient-background">
        <div className="columns">
          {content[selected].map((item: Feature, index: number) => (
            <div key={index} className="column">
              <div className="icon">{item.icon}</div>
              <h3 className="column-title">{item.title}</h3>
              <p className="column-subheading">{item.subheading}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GetStartedSection;
