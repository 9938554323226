import React from "react";
import { FaCheck, FaUser, FaRegClock } from "react-icons/fa"; 
import stepinage from '../Attachments/main-laptop.png';
import '../styles/Download.css'

const WhyJyve: React.FC = () => {
  return (
    <section className="flex items-center justify-between py-16 px-14 why-holder ">
  
      <div className="w-full lg:w-1/2 space-y-6">
        
        <div className="grid grid-cols-1  gap-6">
     
          <div className="flex flex-col items-center column-1 p-6  border border-gray-200 rounded-lg shadow-lg">
            <FaCheck className="text-4xl text-green-500 mb-4" />
            <h3 className="md:text-4xl font-semibold text-white mb-4">Efficiency</h3>
            <p className="text-center text-sm sm:text-base md:text-lg text-gray-600">
              Jyve streamlines processes, saving time and increasing productivity for everyone involved.
            </p>
          </div>

          <div className="flex flex-col items-center column-2 p-6  border border-gray-200 rounded-lg shadow-lg">
            <FaUser className="text-4xl text-green-500 mb-4" />
            <h3 className="md:text-4xl font-semibold text-white mb-4">User-Friendly</h3>
           <p className="text-center text-sm sm:text-base md:text-lg text-gray-600">
              Our platform is intuitive and easy to use, ensuring smooth onboarding and adoption.
            </p>
          </div>

          <div className="flex flex-col items-center column-3 p-6 border border-gray-200 rounded-lg shadow-lg">
            <FaRegClock className="text-4xl text-green-500 mb-4" />
            <h3 className="md:text-4xl font-semibold text-white mb-4">Time Saving</h3>
           <p className="text-center text-sm sm:text-base md:text-lg text-gray-600">
              With Jyve, you can optimize your workflow and save valuable time for more important tasks.
            </p>
          </div>
        </div>
      </div>


      <div className="w-full lg:w-3/4 flex justify-center ">
        <img
          src={stepinage}
          alt="Why Jyve"
          className=" rounded-lg phone"
        />
      </div>
    </section>
  );
};

export default WhyJyve;
