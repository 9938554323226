import React from "react";
import "../styles/hero.css";
import playstore from "../Attachments/Custom SVG/google-play.4cd60f9c4ff9c9b118956d7eceaf3d88.svg";
import apple from "../Attachments/Custom SVG/app-store.6f080d467427024b8f1ab3b49cfd1e25.svg";
import "../styles/hero.css";
import Carousel from "../Components/Carousel";
import carouselImages from "../Components/Carouselimages";
import AppDownload from "../Components/CalltoDownload";
import Wallet1 from "../Attachments/wallet1.png";
import customImage from "../Attachments/wallet2.png";
const Wallet = () => {
  return (
    <>
      <div>
        <section className="relative flex justify-between items-center pt-[200px] heighter pb-16 text-white overflow-hidden">
          <div
            className="texts-container space-y-8 z-10 pl-8 bg-dark text-white"
            style={{ marginTop: "100px" }}
          >
            <h1 className="leading-tight" style={{ color: "white" }}>
              Join Jyve Wallet for seamless and secure transactions.
            </h1>
            <p className="text-lg">
              Experience the Instant payments with Jyve Wallet. Our Wallet makes
              payment easier and faster
            </p>
            <div className="flex space-x-4">
              <a
                href="https://play.google.com/store/apps/details?id=com.zeal.jyve"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={playstore}
                  alt="Button 1"
                  className="w-60 h-auto cursor-pointer"
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/jyve-wallet/id6738971125"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={apple}
                  alt="Button 2"
                  className="w-60 h-auto cursor-pointer"
                />
              </a>
            </div>
          </div>

          <div className="phone-container" style={{ left: "52%" }}>
            <img
              src={Wallet1}
              alt="Phone Mockup"
              className="phone-image"
              style={{ maxWidth: "100%", width: "100%" }}
            />
          </div>
        </section>
      </div>
      <Carousel sources={carouselImages} />

      <div className="dark-background">
        <div className="container mx-auto px-4 py-8">
          <div className="flex flex-col mt-16 md:flex-row items-center">
            <div className="md:w-1/2 flex justify-center mb-8 md:mb-0">
              <img src={customImage} className="w-full shadow-lg" />
            </div>

            <div className="flex flex-col justify-center md:w-1/2 sm:w-full text-left">
              <h2 className="md:text-5xl sm:text-4xl font-bold text-white leading-tight">
                Get Instant Payout With Jyve Wallet
              </h2>
              <p className="text-base sm:text-lg md:text-xl text-gray-300 mt-6 leading-relaxed max-w-md">
                By leveraging Jyve's wallet, you can:
              </p>
              <ul className="text-base sm:text-lg md:text-xl text-gray-300 mt-4 list-disc list-inside">
                <li>Instant payment</li>
                <li>Track Jyve income</li>
                <li>No delay</li>
              </ul>
            </div>
          </div>
        </div>

        <AppDownload
          googlePlayUrl="https://play.google.com/store/apps/details?id=com.zeal.jyve"
          appleStoreUrl="https://apps.apple.com/us/app/jyve-wallet/id6738971125"
        />
      </div>
    </>
  );
};

export default Wallet;
