import React from 'react';
import CounterSection from '../Components/Counter';
import "../styles/CounterHolder.css"; 
import USMap from './usmap';

const CountHolder: React.FC = () => {
  return (
    <div className="count-hold">
      <header className="text-center py-10 mt-10 heading">
        <h1 className="count-text">Jyvers serve the grocery industry’s biggest brands nationwide</h1>
      </header>


      <section className="relative flex justify-center img-holder">
        <USMap />
      </section>

      <CounterSection />
    </div>
  );
};

export default CountHolder;
