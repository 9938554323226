export const numberFormatter = (
  value: number | bigint | string | undefined,
  dp = 2
) => {
  if (!value) return "0";

  if (Number.isNaN(value)) return "0";

  return Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: dp,
  }).format(Number(value));
};
