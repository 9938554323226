import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { scrollToElement } from '../utils/scrollElement'; 
import '../styles/ButtonStyles.css';

const ButtonComponent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const targetId = 'become-jyver';

  const handleClick = () => {
    if (location.pathname === '/home') {

      scrollToElement(targetId);
    } else {
   
      navigate('/', { state: { scrollToElementId: targetId } });
    }
  };

  return (
    <button
      type="button"
      className="custom-button hover:bg-green-800"
      onClick={handleClick}
    >
      Get started
    </button>
  );
};

export default ButtonComponent;
