import { useEffect } from "react";

const GoogleTagManager = ({ gtmId }: { gtmId: string }) => {
  useEffect(() => {
    if (!gtmId) {
      console.warn("Google Tag Manager ID is missing.");
      return;
    }

    const script = document.createElement("script");
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');`;
    script.async = true;
    script.id = "google-tag-manager-script";
    document.head.appendChild(script);

    return () => {
      const existingScript = document.getElementById(
        "google-tag-manager-script",
      );
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [gtmId]);

  return null;
};

export default GoogleTagManager;
