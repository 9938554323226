import React, { useEffect } from 'react';
import Termspage from '../Components/TermsPage';

const MsaPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Termspage/>
    </div>
  );
};

export default MsaPage;
