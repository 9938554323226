import React, { useEffect } from "react";
import videoSrc from "../Attachments/JPP Header Video (1).mp4";
import image1 from "../Attachments/laptop-1.png";
import image2 from "../Attachments/laptop-2.png";
import image3 from "../Attachments/laptop-3.png";
import "../styles/brands.css";
import VideoPlayer from "../Components/FullScreenVideo";
import ContentSwitcher from "../Components/ContentSwitcher";
import FeatureSection from "../Components/featureSection";
import AppDownload from "../Components/CalltoDownload";
import WhyJyve from "../Components/whyjyve";
import Linechart from "../Components/linecharts";
import Accordion from "../Components/Accordion";
import CountHolder from "../Components/CountHolder";

export default function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    // <div className="container">
    //   <div className="left-side">
    //     <h1>Welcome to Our App</h1>
    //     <p>
    //       Experience the future of design and interaction with our 3D viewer. Customize, explore, and bring your ideas
    //       to life.
    //     </p>
    //     <ul>
    //       <li>Engage with cutting-edge 3D models</li>
    //       <li>Built with the power of Spline</li>
    //       <li>Responsive and intuitive design</li>
    //     </ul>
    //   </div>

    //   <div className="right-side ">
    //     <Spline
    //       scene="https://prod.spline.design/Rzi4J7hR2TaVdzkI/scene.splinecode"
    //       style={{ width: '100%', height: '100%' }}
    //     />
    //   </div>
    // </div>

    <div className="brands-backdrop">
      <VideoPlayer
        videoSrc={videoSrc}
        heading=" Transforming How Brands & Retailers Work"
        subHeading="Jyve changes how people work. Our software & marketplace power smarter, 
        more productive & accountable execution for brands & retailers, 
        when and where it matters most – in the store."
        headingStyle={{
          color: "#33ab8f",
          fontSize: "3rem",

          textShadow: "2px 2px 8px rgba(0, 0, 0, 0.7)",
        }}
        subHeadingStyle={{
          color: "white",
          width: "70%",
          fontSize: "1rem",
          textAlign: "center",
          margin: "0 auto",
        }}
        containerStyle={{
          minHeight: "90vh",
        }}
      />
      <FeatureSection />
      <ContentSwitcher
        contentData={contentData}
        interval={2000}
        headerTitle="Start Your Jyving Journey"
        headerDescription="Follow these steps to get started"
      />
      <Linechart />
      <WhyJyve />
      <CountHolder />
      <Accordion />
      <AppDownload
        headerText="Reach Out To Us"
        subHeaderText="Have More Questions?"
        descriptionText="Have questions or want to learn more about our services? Connect with us, We're here to help you get started!"
        isButton={true}
      />
    </div>
  );
}
const contentData = [
  {
    imageUrl: image1,
    title: "Signup",
  },
  {
    imageUrl: image3,
    title: "Post Job",
  },
  {
    imageUrl: image2,
    title: "Hire a Jyver",
  },
];
