import React from 'react';

const terms: React.FC = () => {
  
  return (
    <div className="p-12 mt-12">
        <h3 className="">JYVE USER AGREEMENT</h3>
        <h3>THIS AGREEMENT CONTAINS AN ARBITRATION PROVISION WITH A CLASS/COLLECTIVE ACTION WAIVER.</h3>
      <p className="text-1xl">This Independent Contract Agreement (the "Agreement") applies to the relationship between Abundance Labs Inc., a Delaware corporation, ("Jyve") and you in your capacity as an independent contractor ("You" or "Your"). This Agreement governs Your use of the Jyve hosted platform ("Platform"), the website located at www.Jyve.com (and any other websites, dashboards or portals provided by Jyve) (collectively, the "Website"), Jyve's mobile applications (collectively, the "App"), content and materials and information available via the Website, App or otherwise from Jyve (collectively, "Content") and Jyve's products and services. Collectively, all of the foregoing are referred to as the "Service".

THE SERVICE IS SUBJECT TO YOUR ACCEPTANCE WITHOUT MODIFICATION OF ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN. BY USING OR ACCESSING THE SERVICE - YOU AGREE TO THESE TERMS AND CONDITIONS.

This Agreement also includes any policies (such as, Jyve's FAQ) Jyve's posts on or in the App or Website ("Policies"). Special features provided by Jyve may be subject to different or additional terms or conditions (which are also deemed part of this Agreement). To the extent such terms, conditions or Policies conflict with this Agreement, such different or additional terms, conditions and Policies will control.

This Agreement may be modified at any time by Jyve as set forth in the "Modifications to this Agreement" and “Arbitration Changes" sections below.

YOU MUST BE AT LEAST 18 YEARS OF AGE TO BE A JYVER (AS DEFINED IN THE NEXT SECTION). IF YOU ARE UNDER SUCH AGE - YOU MUST NOT ATTEMPT TO BECOME A JYVER OR OTHERWISE PROVIDE SERVICES HEREUNDER.SCOPE AND LICENSE

The Service allow independent contractors (also called "Jyvers") to select and perform tasks that are identified in the Platform ("Tasks") for Jyve's clients ("Clients"). Subject to Your compliance with this Agreement, Jyve grants You a limited, non-exclusive, revocable, non-sublicensable, non-transferable, limited license to, during the term of this Agreement: (i) access and use the Website and App on Your personal device (such as, a smartphone, tablet or computer) solely in connection with Your use of the Service and (ii) access and use any Content that may be made available through the Service - in each case solely for Your personal use in connection with Your use of, or helping You to understand, the Service. Any rights not expressly granted herein are reserved by Jyve and Jyve's licensors and providers.


TERRITORY

This Agreement governs Your access to and use of the Service in the the Service. United States of America and its territories (collectively, the "Territory"). You must not attempt to access or use the Service if You are outside of the Territory. If You have accessed the Service from outside the Territory (such as, downloading the App), You must immediately cease all use and access of the Service (and delete the App).

TASKS

During the term of this Agreement, You select to perform Tasks via the Platform (such as selecting them when You use the App). You represent that You are duly licensed (if applicable - such as by Federal, state or location governments) and have the qualifications, the experience and the ability to fully and properly perform the Tasks.

REGISTRATION

As a condition to using the Service, You will be required to create a Jyve account ("Account"). Your user ID, together with any other contact information and any other information You provide to Jyve, become Your "Customer Record." You shall provide Jyve with accurate, complete, and updated Customer Record information. Therefore, if any such information changes -You must immediately notify Jyve. Failure to do so shall constitute a breach of this Agreement, which may result in termination of Your account. You must ensure that Your Account contains (at all times) a valid email address and phone number. In addition, You shall not create an account with the intent to impersonate another person. You are solely responsible for activity that occurs on Your Account. You shall never use another user's account without such other user's express permission. You shall never transfer or assign or allow access to Your account to any third party. You will immediately notify Jyve of any unauthorized use of Your Account, or other Account-related security breach of which You are aware (such notification must be sent by You to help@jyve.com).

CERTIFICATION

Jyve may require that You are certified to perform Tasks. Certification may vary based on the Tasks. You agree that Jyver certification is not training- rather it is a determination based upon applicable safety standards and Client specifications as to whether You are already qualified to perform the applicable Tasks. All certification information, materials and content are Jyve's Confidential Information.

FEES

As the sole consideration for the Tasks You complete fully and properly, and Your compliance with all other obligations in this Agreement, the Jyve shall pay to You the amounts specified in the App ("Fees").

EXPENSES

You must provide all necessary equipment to perform Tasks. You shall not be authorized to incur any expenses on behalf of Jyve and/or the Clients. You are responsible for all expenses incurred in connection with the performance of the Tasks, unless Jyve and/or the Clients expressly otherwise agrees in writing.

TIPS AND OTHER CONSIDERATION

You agree that Your only compensation for Tasks that You perform pursuant to this Agreement is the Fees. You will not solicit tips, presents, favors, or things of value from Clients in connection with Your performance of any Tasks.

INSURANCE

Jyve requires You, as an independent contractor, to procure workers compensation insurance, general liability and liability insurance, and any legally required insurance.

TERM AND TERMINATION

(a) You may terminate, without cause, with notice:

You may terminate this Agreement, without cause, on five (5) business days’ notice by sending an email to help@jyve.com, indicating your intent to terminate this Agreement and your full name.

(b) Jyve may terminate, without cause, with notice:

Jyve may terminate this Agreement without cause, on thirty (30) days’ email notice to You at email address in Your Account.

(c) Jyve may terminate for inactivity:

Jyve may terminate this Agreement if You have not been active in Your Service account, meaning, You did not complete at least one Task during the last ninety (90) days. Jyve will provide You with notice via email to the email address in Your Account prior to terminating this Agreement for inactivity, to give You an opportunity to perform at least one Task prior to the termination for inactivity.

(d) Jyve may terminate, with cause, without notice:

Jyve may immediately terminate this Agreement, without notice, if:

(1) Jyve determines that You no longer legally qualify to perform Tasks (such as, for example, if you no longer have an electric pallet jack certification, and one is required to perform Tasks); (2) a Jyve Client makes a complaint about Your performance in connection with any Task; (3) if Jyve has implemented a Jyver rating system (to indicate the quality of Your performance) and your rating falls below a satisfactory level, as determined in Jyve's absolute discretion, (4) Jyve believes in its absolute discretion that You may imminently breach this Agreement in a manner that may damage a Client (or Jyve's reputation with respect to any Client) and that termination is reasonably necessary to avoid such damage, (5) Jyve believes in its absolute discretion that termination is reasonably necessary to protect the safety of Jyve's personnel or any third parties, (6) Jyve believes in its absolute discretion that You have engaged in deceit, dishonesty, misrepresentation, and/or fraud with respect to Jyve, the Tasks, a Client, a Client's customer, Fees, and/or or any right or obligation in this Agreement; (7) You have non-materially breached this Agreement on three (3) or more occasions in a twelve (12) month period; or (8) you have materially breached this Agreement.

(e) You or Jyve may terminate, with cause, with notice:

For all other breaches of this Agreement, the non-breaching party shall provide the breaching party with written notice and a reasonable opportunity to cure the breach. If You are the breaching party, Jyve may suspend your account during the cure period. If Your account is suspended, You will (i) no longer access or use, or attempt to access or use, the Service, and (ii) immediately cease use of all Jyve's Confidential Information.

If the breaching party does not cure the breach in a timely manner and to the non-breaching party's satisfaction, the non-breaching party can terminate this Agreement by providing written notice to the breaching party. If You dispute the existence of a breach, You may submit the matter to arbitration as set forth in the Arbitration Agreement below.

Upon any termination, You will (i) no longer access or use, or attempt to access or use, the Service, (ii) immediately cease use of all Jyve's Confidential Information and (iii) return all information, and all other Jyve property (such as, identification badges), to Jyve.

You will be paid all unpaid, undisputed amounts due for the Tasks fully and properly completed prior to termination.

The following sections will survive termination: "Term and Termination", "Confidential Information", "Feedback", "Links to Third Party Sites, Apps, Products and Services", "Your Representations and Warranties", "Jyve Disclaimers", "Limitation on Jyve's Liability", "Indemnity", "Release", "Notice", "Arbitration", “Severability and Survival”, "Privacy Policy", “Modifications to this Agreement”, "Assignment" and "General" - as well as all restrictions on use of the Service.

CONFIDENTIAL INFORMATION

You agree, at all times during the term of this Agreement and thereafter, not to use, except for the benefit of Jyve and to the extent necessary to perform the Task, and not to disclose to any person, firm, corporation or other entity, without written authorization from Jyve in each instance, any Confidential Information (as defined below) that You obtain, access or create during the term of this Agreement, whether or not during working hours, until such Confidential Information becomes widely known to the public through no wrongful act of You. You further agree not to make copies of such Confidential Information except as authorized by Jyve.

"Confidential Information" means information and materials not generally known or available outside of Jyve or other contractors (including, without limitation, information and materials entrusted to Jyve in confidence by third parties). Confidential Information includes, without limitation: Jyve technical data, trade secrets, know-how, research, product or service ideas or plans, software codes and designs, developments, inventions, processes, techniques, lists of, or information relating to, Clients and/or suppliers and/or partners, fees, pricing, pricing methodologies,cost data, market share data, marketing plans, licenses, contract information, business plans, financial forecasts, historical financial data, budgets or other business or technical information disclosed to You by Jyve either directly or indirectly, whether in writing, electronically, orally, or by observation.

FEEDBACK

In the event You provide Jyve with any suggestions, ideas, improvements or other feedback with respect to any aspect of the Service ("Feedback"), You shall and hereby do grant Jyve (and its successors, licensees and assigns) a non-exclusive, perpetual, irrevocable, sub licensable, transferrable, royalty-free, fully paid-up worldwide right and license to copy, reproduce, modify, create derivatives of, display, perform, sell, offer for sale, distribute and otherwise exploit such Feedback.

LINKS TO THIRD PARTY SITES, APPS, PRODUCTS OR SERVICES

The App, Website or other communications by Jyve to You (such as, by email) may contain links (such as via advertisements) to third party sites, applications, products or services. Jyve does not provide, guarantee or endorse any of the foregoing. You access, purchase, use, or engage with any of the foregoing at Your own risk.

CONFLICTS WITH THIS AGREEMENT OR YOUR PERFORMANCE

You represent and warrant that You are not under any pre-existing obligation with another entity, person, client, employer, or other third party (“Third Party” or “Third Parties”) in conflict or in any way inconsistent with the provisions of this Agreement (including, without limitation, Your performance of any Tasks). You represent and warrant that Your performance of all the terms of this Agreement will not breach any other agreement You have with a Third Party to keep in confidence confidential information acquired by You. You represent and warrant that You have the right to disclose and/or or use all ideas, processes, techniques and other information, if any, which You have gained from Third Parties, and which You disclose to Jyve or use in the course of performance of this Agreement (including, without limitation, Your performance of any Tasks), without liability to such third parties. Notwithstanding the foregoing, You agree that You shall not bundle with or incorporate into any information or materials You provide to Jyve any information, materials, products, services, ideas, processes and/or other techniques belonging to and/or obtained from Third Parties, without the express, written prior approval of Jyve. You represent and warrant that You have not granted, and will not grant, any rights or licenses to any intellectual property and/or technology that would conflict with Your obligation under this Agreement. You represent and warrant that You will not infringe on any copyright, patent, trade secret or other property right of any Third Parties in the performance of the Tasks.

PROVIDING SERVICES TO OTHERS

You are not precluded from doing business with others and Jyve does not restrict You from being concurrently or subsequently engaged in performing services for other companies, even those that compete with Jyve.

YOUR REPRESENTATIONS AND WARRANTIES

You represent, warrant, and agree that:
You will perform the Tasks in a competent, safe, and professional manner.
You will be solely responsible for any and all liability that results from, or is alleged as a result of, Your performance in connection with any Tasks, including, but not limited to personal injuries, death and property damages.
You will comply with all applicable laws, rules and regulations in connection with Your performance of any Tasks, and You will be solely responsible for any violations of such provisions.
You will pay all applicable federal, state and local taxes based on Your performance in connection with the Tasks and any payments received by You (excepting taxes on Jyve's income), and You do not rely on any representations made by Jyve as to the proper tax treatment of any payments received by You.
You will not make any misrepresentation regarding Jyve, the Service, or Your status as an independent contractor.
While performing the Tasks, You will not engage in any activity in a manner that is inconsistent with Your obligations under this Agreement.
You will not attempt to trick, deceive or defraud, or attempt to trick, deceive, or defraud, Jyve and/or any Clients in connection with Your performance of the Tasks. If Jyve suspects that You have engaged in any such activity Jyve may withhold applicable payments to You (in Jyve sole discretion), and that You will cooperate in effectuating any documents related to the withholding.
You will not discriminate against or harass anyone on the basis of race, national origin, religion, gender, gender identity, physical or mental disability, medical condition, marital status, age or sexual orientation, or other status protected by applicable law.
You agree that Jyve may obtain information about You, including Your criminal and driving records, and You agree to provide any further necessary authorizations to facilitate our access to such records during the term of this Agreement.
BACKGROUND CHECKS

Jyve reserves the right to conduct background checks on all Jyvers in its sole discretion. You will be required to provide information to Jyve to conduct such checks (such as Your address and social security number). You will provide any requested information, and otherwise assist Jyve as it requests. The background checks may be conducted by one of Jyve's third party providers. You hereby authorize Jyve and the applicable provider to use and process Your personal information for such purposes.

Jyve may conduct such background checks periodically in its sole discretion, as permitted by applicable law.The scope and nature of background checks will be limited to the extent required by applicable law.

JYVE DISCLAIMER

THE SERVICE (INCLUDING, WITHOUT LIMITATION, THE PLATFORM, APP, WEBSITE, AND ALL MATERIALS, INFORMATION, PRODUCTS AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SERVICE) ARE PROVIDED "AS IS" AND "AS AVAILABLE". THE SERVICE IS PROVIDED WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE HEREBY EXPRESSLY DISCLAIMED TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW. JYVE DOES NOT GUARANTEE OR PROVIDE ANY WARRANTIES TO YOU WITH RESPECT TO HOW MUCH MONEY YOU CAN MAKE USING THE SERVICE (OTHER THAN THE AMOUNTS PAYABLE TO YOU FOR THE FULL, PROPER AND COMPLETE PERFORMANCE OF THE TASKS). SOME STATES DO NOT ALLOW CERTAIN DISCLAIMERS OF WARRANTIES, SO THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU.

LIMITATION ON JYVES LIABILITY

TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL JYVE, ANY JYVE AFFILIATES, SUBSIDIARIES, PARENTS, DIVISIONS, BRANDS, ALLEGED AGENTS, AND ALLEGED JOINT OR CO-EMPLOYERS, AND EACH OF ITS AND THEIR RESPECTIVE FORMER, CURRENT OR FUTURE OFFICERS, DIRECTORS,EMPLOYEES, AGENTS, SHAREHOLDERS, PROVIDER OR SUPPLIERS, (COLLECTIVELY “JYVE PARTIES”) BE LIABLE TO YOU FOR ANY INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL, OR INDIRECT DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR DELETION, CORRUPTION, LOSS OF DATA, LOSS OF PROGRAMS, FAILURE TO STORE ANY INFORMATION OR OTHER CONTENT MAINTAINED OR TRANSMITTED BY THE PLATFORM, SERVICE INTERRUPTIONS) ARISING OUT OF OR IN CONNECTION WITH THE SERVICE OR THIS AGREEMENT, HOWEVER ARISING INCLUDING NEGLIGENCE, EVEN IF JYVE OR ITS AGENTS OR REPRESENTATIVES KNOW OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW, JYVE WILL NOT BE LIABLE IN CONNECTION WITH THE SERVICE OR THIS AGREEMENT FOR AMOUNTS IN THE AGGREGATE IN EXCESS OF US$100.00 (PROVIDED THAT, FOR AMOUNTS OWED BY JYVE FOR YOUR PERFORMANCE OF TASKS SUCH AMOUNT SHALL BE CAPPED AT THAT AMOUNT). TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW, JYVE WILL NOT BE LIABLE FOR ANY DAMAGES, DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL AND/OR CONSEQUENTIAL (INCLUDING, BUT NOT LIMITED TO PHYSICAL DAMAGES, BODILY INJURY, DEATH AND/OR EMOTIONAL DISTRESS AND DISCOMFORT) ARISING OUT OF YOUR COMMUNICATING WITH OR MEETING OTHER USERS OF THE SERVICE (SUCH AS, CLIENTS), EVEN IF JYVE OR ITS AGENTS OR REPRESENTATIVES KNOW OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

JYVE SHALL HAVE NO LIABILITY - OF ANY KIND - FOR THE ACTIONS OF ANY CLIENTS.

CERTAIN JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.

ACCESS TO THE SERVICE

You are responsible for obtaining the data network access necessary to use the Service. Your mobile network's data and messaging rates and fees may apply if You access or use the Service from Your device. You are responsible for acquiring and updating compatible hardware or devices necessary to access and use the Service (such as, the Website and App) and any updates thereto. Jyve does not guarantee that the Service, or any portion thereof, will function on any particular hardware or devices. In addition, the Service may be subject to malfunctions and delays inherent in the use of the Internet and electronic communications.

APPLE TERMS


In the event You are using the App in connection with a device provided by Apple, Inc. ("Apple"), the following shall apply:

Both You and Jyve acknowledge that this Agreement is concluded between You and Jyve only, and not with Apple, and that Apple is not responsible for the App or any content available through the App;

You will only use the App in connection with an Apple device that You own or control;

You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App;

In the event of any failure of the App to conform to any applicable warranty, including those implied by law, You may notify Apple of such failure;

You acknowledge and agree that Apple is not responsible for addressing any claims You or any third party may have in relation to the App;

You acknowledge and agree that, in the event of any third party claim that the App or Your possession and use of the App infringes that third party's intellectual property rights, Apple will not be responsible for the investigation, defense, settlement and discharge of any such infringement claim;

You represent and warrant that You are not located in a country subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country, and that You are not listed on any U.S. Government list of prohibited or restricted parties;

You acknowledge and agree that, in Your use of the App, You will comply with any applicable third party terms of agreement, which may affect or be affected by such use; and

You acknowledge and agree that Apple and Apple's subsidiaries are third party beneficiaries of this Agreement, and that upon Your acceptance of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement against You as the third party beneficiary hereof.

VIDEO, PHOTO AND APPEARANCE RELEASE AND WAIVER

You hereby grant Jyve and its assigns, licensees, successors in interest, legal representatives, and heirs the irrevocable right to use

(i) Your image portrayed in any photo, and Your image and voice portrayed in any video segment, produced or recorded by Jyve or its agents (and any photos taken in connection therewith) (and any composite or distorted representations of such images and/or voice) (collectively, "Video/Photos") and (ii) Your name and the transcript of any statements You have made on or in connection with such Video/Photo, for advertising, trade, or any other lawful

purposes in connection with the promotion of the Jyve business, products, and/or services.

You hereby waive any right to inspect or approve the Video/Photo or printed or electronic matter that may be used in conjunction with them now or in the future, whether that use is known to You or unknown, and You waive any right to royalties or other compensation arising from or related to the use of the Video/Photo. You hereby agree to release, defend, and hold harmless Jyve and its agents or employees, from and against any claims, damages or liability arising from or related to the use of the Video/Photo, including but not limited to libel, defamation, invasion of privacy or right of publicity, infringement of copyright or trademark, misuse, distortion, blurring, alteration, optical illusion or use in composite form, either intentionally or otherwise, that may occur or be produced in connection with the producing or distribution of the Video/Photo. It is the discretion of Jyve to decide whether and how to use the Video/Photo.

Nothing herein shall be construed to obligate Jyve to produce, distribute or use any of the rights granted herein.

INDEMNITY

You will indemnify and hold the Jyve Parties harmless from and against all claims, actions, demands, losses, damages, liabilities, settlements, costs and expenses (including attorneys' fees) arising from or in connection with (i) Your breach of this Agreement or (ii) Your negligent, grossly negligent, intentional, reckless or willful misconduct in connection with Your performance on Tasks, or use of the Service (including, without limitation, as may be raised or brought by any Client). At Jyve's option, it will have the right to solely control all defense and settlement activities (and You will fully cooperate with respect thereto). You will not settle any claim or action or demand without Jyve's prior written consent.

RELEASE

In the event that You have a dispute with one or more Clients, Clients' customers, and/or other Jyvers , You agree to release all Jyve Parties from any and all claims, demands and damages of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected to such disputes with other third party users of the Service (such as, other contractors). Furthermore, You expressly waive any rights You may have under California Civil Code Section 1542 (or analogous laws of other states), which says: "A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which, if known by him or her must have materially affected his or her settlement with the debtor." Jyve reserves the right, but has no obligation, to monitor and help resolve disputes between You and such other users.

RELATIONSHIP OF THE PARTIES; INDEPENDENT CONTRACTOR; NO EMPLOYEE BENEFITS

Notwithstanding any provision hereof, You are an independent contractor and are not an employee. You are not an agent of Jyve and shall not bind or attempt to bind Jyve to any contract.

You acknowledge and agree that Jyve's usual course of business is providing technology to connect Clients with Jyvers; that Your usual course of business is to provide merchandising services; that Jyve's usual course of business does not include the provision of merchandising services or the performance of Tasks; and that the merchandising services You provide and the Tasks that You perform as a Jyver are outside Jyve's usual course of business.

You represent and agree that that You customarily offer to provide, and do in fact provide, services similar to the Tasks for third parties, other than through Jyve's Service.

You are an independent provider of merchandising services, authorized to conduct the Tasks set forth in this Agreement in the geographic locations in which You operate. You desire to enter into this Agreement for the right to receive Task opportunities through the Jyve Services. You understand and expressly agree that You are not an employee or agent of Jyve. You understand and agree that:

(i) You are free to accept or reject any opportunities submitted through the Jyve Services, and can make such decisions in Your sole discretion for any reason, including to maximize Your opportunity for profit; (ii) You are free to select the times and dates on which You wish to perform Tasks; and (iii) You have the sole right to control the manner in which the Tasks are to be performed, and the means by which the Tasks are completed, subject only to any restrictions or parameters imposed by the Client.

You will not do anything they should reasonably understand will, or is likely to, cause any third party to think or otherwise believe that You are an employee or agent of Jyve. For example, You will not state (verbally or in any other form of communication, such as email) to any third party that You are an employee or agent of Jyve or use business cards associated with Jyve.

You shall not be eligible to participate in any of Jyve1s employee benefit plans, fringe benefit programs, group insurance arrangements or similar programs. Jyve shall not provide workers' compensation, disability insurance, Social Security or unemployment compensation coverage or any other statutory benefit to You. You shall comply at Your expense with all applicable provisions of workers' compensation laws, unemployment compensation laws, federal Social Security law, the Fair Labor Standards Act, federal, state and local income tax laws, and all other applicable federal, state and local laws, regulations and codes relating to terms and conditions of employment required to be fulfilled by employers or independent contractors.

TASK INFORMATION

You may be required to provide information to Jyve concerning the Tasks You perform so that Jyve may review the final results of the Tasks and ensure compliance with this Agreement.

NOTICE

Except as set forth in this Agreement, all notices to Jyve in connection with this Agreement shall be in writing and shall be deemed given when personally delivered, or three days after being sent by prepaid certified or registered U.S. mail to 340 5. Lemon Avenue #5983, Walnut, CA 91789 or to such other address as Jyve may provide You. If notices are in regard to questions or issues regarding privacy, they should include the following as a subject heading or otherwise in a prominent manner: Attn: Privacy. If notices are in regard to legal actions or claims, they should include the following as a subject heading or otherwise in a prominent manner: Attn: Legal.

Jyve may give notice by means of a general notice posted on the Website or in the App, email to Your email address in Your Account, telephone or text message to any phone number in Your Account, or by written communication sent by first class mail or pre-paid post to the address in Your Account. Such notice shall be deemed to have been given either (i) upon the expiration of forty eight (48) hours after mailing (if sent by first class mail or pre-paid post), (ii) if sent by email or phone, eighteen (18) hours after sending or (iii) upon Your access to the Website or App and being provided with such notice (such as, by means of a pop-up screen).

EMAIL CONFIRMATION AND ALERT

Jyve may from time to time provide automatic e-mail or SMS confirmations and account-related alerts. Confirmation messages and alerts are active by default as part of the Service. Jyve may add new alerts from time to time or cease to provide certain alerts at any time in its sole discretion.

Confirmations and alerts will be sent based on contact information and preferences in Your Account. If Your contact information changes, You are responsible for informing us of that change.

You understand and agree that any confirmations and alerts provided to You through the Service may be delayed or prevented by a variety of factors. Jyve does its best to provide alerts in a timely manner with accurate information. However, Jyve neither guarantees the delivery nor the accuracy of the content of any confirmation or alert. You also agree that Jyve shall not be liable for any delays, failure to deliver, or misdirected delivery of any confirmation or alert; for any errors in the content of a confirmation or alert; or for any actions taken or not taken by You or any third party in reliance on a confirmation or alert.

In connection with this Agreement, and any Tasks, Jyve and its providers may send You notices and instructions, via text SMS messages. You hereby authorize Jyve and its providers to send such messages - and agree that You are responsible for any charges imposed by Your phone service provider.

ARBITRATION

By agreeing to this Agreement, You agree to this Arbitration provision (including all below sections through and including Arbitration Changes) (collectively the “Arbitration Agreement”) and agree that You are required to resolve any claim that You may have against the Jyve Parties, and against its Clients and partners, on an individual basis in arbitration, as set forth in this Arbitration Agreement. This will preclude You from bringing any class, collective, or consolidated action (and, to the extent allowed by law, any representative action) against the Jyve Parties, and against its Clients and partners, and also preclude You from participating in or recovering relief under any current or future class, collective, or consolidated action (and, to the extent allowed by law, any representative action) brought against the Jyve Parties, and against its Clients and partners, by someone else.

AGREEMENT TO MUTUAL BINDING ARBITRATION BETWEEN YOU AND JYVE:

You and Jyve agree that any dispute, claim or controversy arising out of or relating to (a) this Agreement or the existence, breach, termination, enforcement, interpretation or validity thereof, (b) Your access to or use of the Service at any time, (c) disputes relating to or arising from claims for breach of contract; wrongful discharge; negligence or other tort claims including defamation, libel, slander, fraud, invasion of privacy, and infliction of emotional distress; claims for misclassification, wages, overtime, benefits, or other compensation; and all other statutory, regulatory, contractual, or tort claims or claims of violation of any international, federal/national, state/provincial, or local statute, ordinance, governmental enactment, common law duty, provision, or doctrine will be settled by binding arbitration between You and Jyve, and not in a court of law. The only disputes and actions excluded are claims which this arbitration agreement cannot, as a matter of applicable law, bind the parties to resolve through arbitration.

You acknowledge and agree that You are waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class, collective, or consolidated action (and, to the extent permitted by law, representative proceeding). Unless both You and Jyve otherwise agree in writing, any arbitration will be conducted only on an individual basis and not in a class, collective or consolidated proceeding (and, to the extent permitted by law, not in a representative proceeding). Excluded from this Arbitration Agreement are small claims actions brought on an individual basis that are within the scope of the small claims court's jurisdiction. However, Jyve retains the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of its confidentiality rights or copyrights, trademarks, trade secrets, patents or other intellectual property rights.

GOVERNING LAW AND RULES:

The arbitration will be administered by the American Arbitration Association ("AAA") in accordance with the AAA's Commercial Arbitration Rules (the "AAA Rules") then in effect, except as modified by this "Arbitration" section. The AAA Rules are available at https:// www.adr.org/Rules or by calling the AAA at 1-800-778- 7879.

The parties agree that the arbitrator ("Arbitrator"), and not any federal, state, or local court or agency, shall have exclusive authority to resolve any disputes relating to the interpretation, applicability, enforceability or formation of this Arbitration Agreement, including, without limitation, any claim that all or any part of this Arbitration Agreement is void or voidable. The Arbitrator shall also be responsible for determining all threshold arbitrability issues.

Notwithstanding any choice of law or other provision in this Agreement, the parties agree and acknowledge that this Arbitration Agreement evidences a transaction involving interstate commerce and that the Federal Arbitration Act, 9 U.S.C. § 1 et seq. ("FAA"), will govern its interpretation and enforcement and proceedings pursuant thereto. It is the intent of the parties that the FAA and AAA Rules shall preempt all state laws to the fullest extent permitted by law. If the FAA and AAA Rules are found to not apply to any issue that arises under this Arbitration Agreement or the enforcement thereof, then that issue shall be resolved under the laws of the state of California.

PROCESS:

If You or Jyve desire to initiate arbitration, that party must provide the other party with a written Demand for Arbitration as specified in the AAA Rules. (The AAA provides a form Demand for Arbitration

- Commercial Arbitration Rules at www.adr.org or by calling the AAA at 1-800-778-7879).

The Arbitrator will be selected by the parties from the AAA's roster of consumer dispute arbitrators. If the parties are unable to agree upon an Arbitrator within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the Arbitrator in accordance with the AAA Rules.

LOCATION AND PROCEDURE:

Unless You and Jyve otherwise agree, the arbitration will be conducted in the county where You reside. If Your claim does not exceed $10,000, then the arbitration will be conducted solely on the basis of documents You and Jyve submit to the Arbitrator, unless You request a hearing or the Arbitrator determines that a hearing is necessary. If Your claim exceeds $10,000, Your right to a hearing will be determined by the AAA Rules.

Subject to the AAA Rules and this Arbitration Agreement, the Arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration. The arbitrator shall have the same authority to order remedies to individual claimants as would a court of competent jurisdiction. The arbitrator shall also have the same authority to decide dispositive motions as would a court, though they may establish appropriate and less formal procedures for such motions consistent with the expedited nature of arbitration proceedings. The arbitrator shall allow the parties to conduct adequate discovery, to include, without limitation, issuing subpoenas to compel the attendance of witnesses at the arbitration hearing, conducting depositions, and to compel the production of documents during discovery.

ARBITRATOR'S DECISION:

The Arbitrator will render an award within the time frame specified in the AAA Rules. Judgment on the arbitration award may be entered in any court having competent jurisdiction to do so. The Arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted by the claimant's individual claim. An Arbitrator's decision shall be final and binding on all parties. An Arbitrator's decision and judgment thereon shall have no precedential or collateral estoppel effect. The prevailing party in arbitration will be entitled to an award of attorneys' fees and expenses, to the extent provided under applicable law.

ARBITRATION FEES:

Jyve shall pay all costs unique to arbitration (as compared to the costs of adjudicating the same claims before a court or agency), including the fees charged by AAA, as described in the applicable AAA fee schedule. However, if You initiate the claim, You shall be responsible for the filing fee to initiate the claim with arbitration. Rule 68 of the Federal Rule of Civil Procedure ("Offer of Judgment") shall apply to the arbitration proceedings, and related costs, as if they were a trial or other scheduled proceeding for assessing the extent of liability.

ARBITRATION CHANGES:

Notwithstanding the sections in this Agreement, regarding consent to be bound by amendments to this Agreement, if Jyve changes this Arbitration Agreement after the date You first became bound by this Agreement (“Changes”), You may reject any such Change(s) by providing Jyve written notice of such rejection within thirty (30) days of the effective date of Change(s). This written notice must be provided either (a) by mail or hand delivery to 340 S. Lemon Ave #5983, Walnut, CA 91789 Attn: Jyve, or (b) by email from the email address associated with Your Account to: legal@jyve.com. In order to be effective, the notice must include Your full name, the change(s) at issue, and clearly indicate Your intent to reject Change(s) to this Arbitration Agreement. By rejecting Changes (including, by failing to provide the foregoing rejection notice), You are agreeing that You will arbitrate any dispute between You and Jyve in accordance with the provisions of this Arbitration Agreement section as of the date You become bound by this Agreement (or the effective date of the Change(s), as applicable).

INTENDED THIRD-PARTY BENEFICIARIES:

The parties intend that Jyve's Clients and partners are intended third-party beneficiaries of this Arbitration Agreement, with the right to enforce it.

SEVERABILITY AND SURVIVAL:

If any portion of this Arbitration Agreement is found to be unenforceable or unlawful for any reason, (1) the unenforceable or unlawful provision shall be severed from this Agreement; (2) severance of the unenforceable or unlawful provision shall have no impact whatsoever on the remainder of this Arbitration Agreement or the parties' ability to compel arbitration of any remaining claims on an individual basis pursuant to this Arbitration Agreement; and (3) to the extent that any claims must therefore proceed on a class, collective, consolidated, or representative basis, such claims must be litigated in a civil court of competent jurisdiction and not in arbitration, and the parties agree that litigation of those claims shall be stayed pending the outcome of any individual claims in arbitration.

PRIVACY POLICY

Jyve's privacy practices are set forth in the Jyve privacy policy located at www.jyve.com/privacy ("Privacy Policy"). You should review such policy carefully. By using the Jyve Services, you agree to comply with the Privacy Policy.

MODIFICATIONS TO THIS AGREEMENT

Jyve may change or modify this Agreement (including, its terms and/or conditions). If Jyve does so, it will notify You as set forth in the "Notice" section above. Except as set forth in the “Arbitration Changes" section above - You will be bound to such changes five

(5) days after receipt of such notice. You may reject such cChanges by terminating Your use of the Website, App or any other part of Service.

ASSIGNMENT

This Agreement and the services contemplated hereunder are personal to You and You shall not have the right or ability to assign, transfer or subcontract any rights or obligations under this Agreement. Any attempt to do so shall be void. Jyve may fully assign and transfer this Agreement in whole or part at any time.

GENERAL

The failure of either party to enforce its rights under this Agreement at any time for any period shall not be construed as a waiver of such rights.

In the event that any provision of this Agreement shall be determined to be illegal or unenforceable, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement shall otherwise remain in full force and effect and be enforceable.

This Agreement shall be governed by and construed in accordance with the laws of the State of Delaware without regard to the conflicts of laws provisions thereof.

Headings herein are for convenience of reference only and shall in no way affect interpretation of the Agreement.

WHISTLEBLOWER NOTICE:

You acknowledge receipt of the following notice under 18 U.S.C § 1833(b)(1): "An individual shall not be held criminally or civilly liable under any Federal or State trade secret law for the disclosure of a trade secret that (A) is made (i) in confidence to a Federal, State, or local government official, either directly or indirectly, or to an attorney; and (ii) solely for the purpose of reporting or investigating a suspected violation of law; or (B) is made in a complaint or other document filed in a lawsuit or other proceeding, if such filing is made under seal."</p>
    </div>
  );
};

export default terms;
