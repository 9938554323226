import React, { useEffect } from 'react';
import Privacypage from '../Components/PrivacyPage';

const Privacy: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Privacypage/>
    </div>
  );
};

export default Privacy;
