import React, { useEffect, useState } from 'react';
import "../styles/Counter.css";

interface CounterProps {
  target: number;
  speed: number;
  title: string;
}


const formatNumber = (num: number): string => {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + 'M+';
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + 'K+';
  }
  return num.toString();
};

const Counter: React.FC<CounterProps> = ({ target, speed, title }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const increment = target / (speed / 100);

    const counterInterval = setInterval(() => {
      start += increment;
      if (start >= target) {
        clearInterval(counterInterval);
        setCount(target);
      } else {
        setCount(Math.ceil(start));
      }
    }, 100);

    return () => clearInterval(counterInterval);
  }, [target, speed]);

  return (
    <div className="counter-container">
  
      <span className="counter">{formatNumber(count)}</span>
      <p className="counter-sub">{title}</p>
    </div>
  );
};

const CounterSection: React.FC = () => {
  return (
    <div className="counter-hold">
      <h1 className="section-title">Our Achievements</h1>
      <p className="section-subtitle">We take pride in our numbers! </p>
      <div className="counter-wrap">
        <Counter target={1_000_000} speed={4000} title="JYVES DONE" />
        <Counter target={53_030_000} speed={4000} title="CASES STOCKED" />
        <Counter target={1460} speed={4000} title="CITIES SERVED" />
      </div>
    </div>
  );
};

export default CounterSection;
