import React, { useEffect, useRef, useState } from "react";
import "../styles/Calltoaction.css";
import hero from "../Attachments/hero-img.png";
import BlobButton from "./mactab";
const HeaderSection: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.2 },
    );

    const currentSectionRef = sectionRef.current;

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  return (
    <div id="become-jyver" ref={sectionRef} className="header-section">
      <div className={`text-content ${isVisible ? "visible" : ""}`}>
        <div className="text-center">
          <h1 className="header-title">BEGIN YOUR JYVING JOURNEY TODAY!</h1>
          <div className="px-3">
            <p className="subtext">
              Pick flexible retail gigs, set your own schedule, and start
              earning-all through your smartphone. It’s easy to get started!
            </p>
            <p className="subtext">Click one to know more</p>
          </div>
        </div>
        <div className="buttons-container">
          <BlobButton
            title="Become a Jyver"
            imageSrc="https://22604708.fs1.hubspotusercontent-na1.net/hubfs/22604708/unisex-performance-crew-neck-t-shirt-black-left-front-6504809e57b70.png"
            imageAlt="become a jyver"
            tags={["Be your own boss. you choose when and where you work"]}
          />

          <BlobButton
            title="Join as a brand/retailer"
            imageSrc={hero}
            imageAlt="test"
            link="https://client.jyve.com/login"
            tags={["Let jyvers assist  you, click here to hire a jyver today"]}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
