import React, { useState } from "react";

interface AccordionItem {
  title: string;
  content: string;
}

const accordionData: AccordionItem[] = [
  {
    title: "How Does Jyve Work?",
    content: `
      1. Post a Job: List tasks like stocking shelves, managing inventory, or other in-store needs.
      2. Get Matched: Jyve connects you with skilled, reliable workers in your area.
      3. Track Progress: Monitor task completion in real time through the Jyve app.
      4. Pay Securely: Effortless, hassle-free payments once the job is done.

      Jyve makes staffing simple, fast, and effective.`,
  },
  {
    title: "What Kind of Jobs Can I Post?",
    content: `
      Jyve is perfect for operational tasks in retail, logistics, and beyond. Common tasks include:
      - Shelf stocking and product restocking
      - Merchandising displays and organizing inventory
      - Retail audits and inventory checks
      - Cleaning, sorting, and in-store support tasks

      If you have a task, Jyve has the workers to get it done.`,
  },
  {
    title: "Why Should I Use Jyve Instead of Traditional Staffing?",
    content: `
      Traditional staffing models are slow, expensive, and inflexible. Jyve offers:
      - Speed: Post jobs and get workers fast.
      - Flexibility: Hire workers only when you need them—no commitments.
      - Cost Efficiency: Pay per task, not per hour, reducing overhead.
      - Quality: Access a pool of vetted, skilled individuals ready to perform.

      Jyve helps you save time, money, and resources while improving results.`,
  },
  {
    title: "Who Are the Workers on Jyve?",
    content: `
      Jyve’s workforce consists of skilled individuals who specialize in operational tasks. Each worker is:
      - Vetted and Verified: Profiles are checked to ensure reliability.
      - Trained: Ready to complete tasks efficiently and professionally.
      - Flexible: Workers accept tasks based on their availability, ensuring you get timely support.

      Jyve connects you with the right people for the job, every time.`,
  },
  {
    title: "How Much Does Jyve Cost?",
    content: `
      Jyve operates on a pay-as-you-go model, ensuring you only pay for completed tasks. Pricing is:
      - Transparent: No hidden fees or extra costs.
      - Affordable: Competitive rates compared to traditional staffing.
      - Flexible: You control the budget by setting the scope of each job.

      Post a task, set your budget, and get quality work delivered without the hassle.`,
  },
  {
    title: "Is Jyve Right for My Business?",
    content: `
      Jyve is perfect for businesses in retail, logistics, warehousing, or any industry needing task-based labor. If you:
      - Struggle to find reliable workers quickly
      - Need flexible staffing to meet operational demands
      - Want to improve efficiency without long-term commitments

      … then Jyve is the solution for you.`,
  },
];

const Accordion: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="w-full mx-auto">
        {accordionData.map((item, index) => (
          <div key={index} className="border-b border-gray-500">
         
            <button
              onClick={() => toggleAccordion(index)}
              className="w-full text-left py-4 px-6 flex justify-between items-center text-teal-400 text-lg font-medium hover:bg-gray-800 hover:bg-opacity-30 focus:outline-none transition"
              style={{ background: "transparent" }}
            >
              <span className="text-green-400 text-2xl">{item.title}</span>
              <span className="text-2xl text-gray-400">
                {activeIndex === index ? "−" : "+"}
              </span>
            </button>

        
            <div
              className={`overflow-hidden transition-all duration-300 ${
                activeIndex === index ? "max-h-96" : "max-h-0"
              }`}
            >
              <div className=" px-6 py-4 text-gray-100 whitespace-pre-line">
                {item.content}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
