import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import NavBar from './Components/Navbar';
import routes from './routes';
import Footer from './Components/Footer';

const App = () => (
  <Router>
    <NavBar />
    <Routes>
 
      {routes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
      <Route path="*" element={<Navigate to="/" replace />} /> {/* Fallback */}
    </Routes>
    <Footer/>
  </Router>
);

export default App;
