import React, { createContext, useState, ReactNode } from "react";


interface StackingContextType {
  activeRow: number; 
  setActiveRow: (row: number) => void; 
}


const StackingContext = createContext<StackingContextType | undefined>(undefined);


export const StackingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeRow, setActiveRow] = useState(0); 

  return (
    <StackingContext.Provider value={{ activeRow, setActiveRow }}>
      {children}
    </StackingContext.Provider>
  );
};



