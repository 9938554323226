import React, { useEffect, useRef } from "react";
import "../styles/stackingtwo.css";
import frameA from "../Attachments/Jyve-shopping-cart_1548363612.jpg";
import frameB from "../Attachments/Jyve-blurb-1080x675.jpg";

const StackingTwo: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const rows = containerRef.current.querySelectorAll(".stacking-row");

        rows.forEach((row, index) => {
          if (containerTop <= -index * 100) {
            row.classList.add("stacked");
          } else {
            row.classList.remove("stacked");
          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <header className="text-white text-center mt-12">
        <h1 className="headings md:text-6xl font-semibold">
          How Jyve Supports You
        </h1>
        <p className="text-base sm:text-lg md:text-xl mt-2">
          Enhancing Retail, Brands, and Events with Jyve
        </p>
      </header>

      <div ref={containerRef} className="stacking-container">
        <div
          className="stacking-row darken-row row-1"
          id="retailers"
          style={{
            backgroundImage: `url(${frameB})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="text-overlay">
            <div className="icon-circle">
              <span>01</span>
            </div>
            <h3 className="bold-heading">Instant Access to Jobs</h3>
            <p className="body-text bodyMedium">
              With Jyve, finding and starting work has never been easier.
              Whether you’re interested in shelf-stacking, inventory management,
              or other on-demand tasks, the app gives you immediate access to
              job opportunities. No long application processes or waiting
              around—just pick a task, complete it, and get paid fast.
            </p>
          </div>
        </div>

        <div
          className="stacking-row darken-row  row-2"
          id="brands"
          style={{
            backgroundImage: `url(https://techcrunch.com/wp-content/uploads/2019/01/Jyve-5.jpg?w=680)`,
            backgroundSize: "cover",
          }}
        >
          <div className="text-overlaytwo">
            <div className="icon-circle">
              <span>02</span>
            </div>
            <h3 className="bold-heading">
              Flexibility to Choose Where and When You Work
            </h3>
            <p className="body-text bodyMedium">
              Jyve puts the power of choice in your hands. Whether you want to
              work mornings, afternoons, weekends, or even late nights, you can
              choose the time that works best for you. Plus, with the ability to
              select where you work, Jyvers have the freedom to pick jobs that
              are convenient for them.
            </p>
          </div>
        </div>

        <div
          className="stacking-row darken-row  row-3"
          id="events"
          style={{ backgroundImage: `url(${frameA})`, backgroundSize: "cover" }}
        >
          <div className="text-overlay">
            <div className="icon-circle">
              <span>03</span>
            </div>
            <h3 className="bold-heading">Quick Payments for Quick Work</h3>
            <p className="body-text bodyMedium">
              One of the biggest advantages of working with Jyve is the instant
              payment feature. After you complete a job like stock auditing or
              other duties, you get paid right away—no waiting for a paycheck at
              the end of the day.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StackingTwo;
