import React, { useEffect } from 'react';
import HeroSection from '../Components/jyverHero';
import image1 from "../Attachments/Custom SVG/first.svg";
import heroimage from "../Attachments/Contact us-bro.png"
import image2 from "../Attachments/Custom SVG/second.svg";
import image3 from "../Attachments/Custom SVG/third.svg";
import '../styles/hero.css'
import FeatureSection from '../Components/featureSection';
import AppDownload from '../Components/CalltoDownload';


const Workers = () => {
 
    useEffect(() => {
    
      window.scrollTo(0, 0);
    }, []);

  return (
    <>
   
   <HeroSection 
  phoneImageSrc={heroimage} 
  headingText="Contact us, we'd love to hear from you" 
  subheadingText="Have any Questions?" 
  buttonText="Contact Us" 
  buttonLink="https://support.jyve.com/hc/en-us/requests/new"
  phoneImageStyle={{
   minWidth:"65%"
  }}
/>
<FeatureSection 
  sectionTitle="Our Key Features"
  features={[
    {
      image: image1,
      title: "Help Center",
      body: "FAQs and How-Tos",
      buttonText: "Get help",  
      buttonLink: "https://support.jyve.com/hc/en-us",  
    },{
        image: image2,
        title: "Jyve Support",
        body: "Jyver or current customer?",
        buttonText: "Contact Jyve",  
        buttonLink: "https://support.jyve.com/hc/en-us/requests/new",  
      },
    {
      image: image3,
      title: "Sales Team",
      body: "Learn more about Jyve",
      buttonText: "Learn More",  
      buttonLink: "mailto:sales@jyve.com",
     
    },
    
  ]}
/>
<AppDownload 
  headerText="Become a jyver today" 
  subHeaderText="Begin your jyving journey" 
  descriptionText="Download our app and start jyving today"
  
/>

     
    </>
  );
};

export default Workers;

