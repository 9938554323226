import React from 'react';
import '../styles/carousel.css'; 

interface CarouselProps {
  sources: string[];
}

const Carousel: React.FC<CarouselProps> = ({ sources }) => {
  const duplicatedSources = [...sources, ...sources,...sources,...sources,...sources,...sources,...sources]; 

  return (
    <>
    <div className="carousel-container">
      <div className="slide-track">
        {duplicatedSources.map((source, index) => (
          <div key={index} className="slide">
            <img src={source} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
    <div >
    </div>
  </>
);
};
export default Carousel;
