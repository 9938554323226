import React, { useEffect } from "react";
import VideoPlayer from "../Components/FullScreenVideo";
import videoSrc from "../Attachments/banner1.mp4";
import Carousel from "../Components/Carousel";
import carouselImages from "../Components/Carouselimages";
import Animated from "../Components/Animator";
import CountHolder from "../Components/CountHolder";
import CallToActionComponent from "../Components/Calltoaction";
import Sticky from "../Components/Sticky";
import GetStarted from "../Components/getStarted";
import "../styles/home.css";
import AppDownload from "../Components/CalltoDownload";
import { useLocation } from "react-router-dom";

const Home: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const { scrollToElementId } = location.state || {};
    if (scrollToElementId) {
      setTimeout(() => {
        const targetElement = document.getElementById(scrollToElementId);
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
    }
  }, [location]);

  return (
    <div>
      <VideoPlayer
        videoSrc={videoSrc}
        heading="A NEXT-GEN LIGHT LABOR WORKFORCE SOLUTION"
        subHeading="LEVERAGING AI - WE ARE A DYNAMIC SERVICE BUILT BY WORKERS & FOR CUSTOMERS."
        contentOverlayStyle={{ top:"80%" }}
      />
      <Carousel sources={carouselImages} />
      <Animated />
      <div className="get-started-wrapper">
        <Sticky />
        <GetStarted />
        <CountHolder />
    
          <CallToActionComponent />
    
      </div>
      <AppDownload />
    </div>
  );
};

export default Home;
