import React, { useEffect, useRef, useState } from "react";
import hero from "../Attachments/hero-img.png";
import "../styles/Test.css";

const AnimatedComponent: React.FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const shapeRef = useRef<HTMLDivElement>(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          } else {
            setIsInView(false);
          }
        });
      },
      { threshold: 0.5 }
    );

    const currentRef = componentRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div
      ref={componentRef}
      className="animated-container">
      <div
        ref={imageRef}
        className={`animated-image ${isInView ? "in-view" : "out-of-view"}`}>
        <img
          src={hero}
          alt="Animated"
        />
      </div>
      <div
        ref={shapeRef}
        className={`animated-shape ${isInView ? "in-view" : "out-of-view"}`}>
        <h1 className="header-text">
          Flexibility.
          <br />
          Freedom.
          <br />
        </h1>
        <p className="sub-text">
          Join Jyve to choose when , where and how often you work. Get the Jyve
          app now and begin your Jyving journey today!
        </p>
      </div>
    </div>
  );
};

export default AnimatedComponent;
