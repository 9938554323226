import React from 'react';
import '../styles/VideoPlayer.css';

interface VideoPlayerProps {
  videoSrc: string;
  heading: string;
  subHeading: string;
  headingStyle?: React.CSSProperties;
  subHeadingStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  headingClassName?: string; 
  contentOverlayStyle?: React.CSSProperties; 
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoSrc,
  heading,
  subHeading,
  headingStyle,
  subHeadingStyle,
  containerStyle,
  headingClassName,
  contentOverlayStyle,  
}) => {
  return (
    <div className="video-container" style={containerStyle}>
      <video
        className="video-background"
        src={videoSrc}
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
      />
      <div className="overlay theme-gradient"></div>
      <div className="content-overlay" style={contentOverlayStyle}> {/* Apply the style override */}
        <h1 className={`main-heading ${headingClassName}`} style={headingStyle}>
          {heading}
        </h1>
        <p className="sub-heading" style={subHeadingStyle}>
          {subHeading}
        </p>
      </div>
    </div>
  );
};

export default VideoPlayer;
