import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Blob.css';

type ProjectDisplayProps = {
  title: string;
  imageSrc: string;
  imageAlt?: string;
  tags?: string[];
  link?: string;  
};

const ProjectDisplay: React.FC<ProjectDisplayProps> = ({
  title,
  imageSrc,
  imageAlt = '',
  tags = [],
  link,
}) => {

  const handleClick = (e: React.MouseEvent) => {
    
    if (!link) {
      e.preventDefault();
      const targetElement = document.getElementById('scroll-target');
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  const content = (
    <div className="projects-div-border">
      <div className="projects-div-orbs">
        <div className="mac-dots-group">
          <div className="mac-dot red"></div>
          <div className="mac-dot yellow"></div>
          <div className="mac-dot green"></div>
        </div>
        <div className="portfolio-image-wrapper">
          {link ? (
          
            <Link target="_blank" to={link}>
              <img
                src={imageSrc}
                alt={imageAlt}
                className="portfolio-image"
                width="820"
                loading="lazy"
              />
            </Link>
          ) : (
          
            <div onClick={handleClick}>
              <img
                src={imageSrc}
                alt={imageAlt}
                className="portfolio-image"
                width="820"
                loading="lazy"
              />
            </div>
          )}
        </div>
      </div>
      <div className="projects-details-wrapper">
        {link ? (
        
          <Link to={link} target="_blank">
            <h3 className="heading-style-h4">{title}</h3>
          </Link>
        ) : (
         
          <h3 className="heading-style-h4" onClick={handleClick}>
            {title}
          </h3>
        )}
        <div className="spacer-xsmall"></div>
        <div className="projects-tag-group">
          {tags.map((tag, index) => (
            link ? (
             
              <Link key={index} to={link} target="_blank" className="tag-link">
                <div>{tag}</div>
              </Link>
            ) : (
             
              <div key={index} className="tag-link">
                <div>{tag}</div>
              </div>
            )
          ))}
          <div className="icon-embed-xsmall w-embed">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="100%"
              height="100%"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 7L7 17M8 7h9v9"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};

export default ProjectDisplay;
