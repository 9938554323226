import React, { useEffect } from "react";
import HeroSection from "../Components/jyverHero";
import GoogleTagManager from "../Components/Googletagmanager";
import image1 from "../Attachments/1.png";
import image2 from "../Attachments/Soft Minimalist Download Phone Mockup (3).png";
import image3 from "../Attachments/3.png";
import "../styles/hero.css";
import Carousel from "../Components/Carousel";
import carouselImages from "../Components/Carouselimages";
import ContentSwitcher from "../Components/ContentSwitcher";
import StackingTwo from "../Components/stackingTwo";
import Accordion from "../Components/Accordion";
import AppDownload from "../Components/CalltoDownload";
import LineChart from "../Components/linecharts";
import customImage from "../Attachments/finance-graph.png";
const WORKERS_GTM_ID = "GTM-PJZRTWK";
const Workers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const contentData = [
    {
      imageUrl: image1,
      title: "Signup",
    },
    {
      imageUrl: image2,
      title: "Demo your skills ",
    },
    {
      imageUrl: image3,
      title: "Get paid",
    },
  ];
  return (
    <>

      <GoogleTagManager gtmId={WORKERS_GTM_ID} />

      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${WORKERS_GTM_ID}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
      <div className="background-dark">
        <HeroSection />{" "}
      </div>
      <Carousel sources={carouselImages} />

      <div className="dark-background">
        <ContentSwitcher
          contentData={contentData}
          interval={3500}
          headerTitle="Start Your jyving Journey"
          headerDescription="Follow these steps to get started"
        />{" "}
        <LineChart
          title="Enhance Your Finances with Jyve"
          description="By leveraging Jyve's flexible on-demand platform, you can quickly increase your income, manage your work schedule, and get paid faster.  "
          imageSrc={customImage}
          altText="Custom Operational Efficiency"
        />
        <StackingTwo />
        <Accordion />
        <AppDownload />
      </div>
    </>
  );
};

export default Workers;
